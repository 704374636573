
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLogged = computed(() => store.getters.isLoggedIn);
    const username = computed(() => store.state.username);

    const logout = async () => {
      await store.dispatch('logout');
      router.push('/login');
    };

    return {
      isLogged,
      username,
      logout,
    };
  },
});
