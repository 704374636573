import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store/index';

const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'Home',
      component: ()=> import('../views/Home.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: ()=> import('../views/Login.vue'),
    },
    {
        path: '/demandemdp',
        name: 'MotDePasseOublie',
        component: () => import('../views/Connection/MotDePasseOublie.vue'),
    },
    {
        path: '/reinitmdp/:token',
        name: 'ResetPassword',
        component: () => import('../views/Connection/ResetPassword.vue'),
        props:true,
    },
    {
      path: '/applications',
      name: 'Applications',
      component: () => import('../views/Applications.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
      path: '/methodes',
      name: 'Methodes',
      component: () => import('../views/Methodes.vue'),
        meta: {
            requiresAuth: true,
        },
    },
  ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.meta.requiresAuth as boolean;

    // Permet l'accès à la route de réinitialisation du mot de passe sans authentification
    if (to.name === 'ResetPassword') {
        next();
        return;
    }

    // Si la route nécessite une authentification et l'utilisateur n'est pas connecté, redirige vers "/login"
    if (requiresAuth && !store.getters.isLoggedIn) {
        next('/login');
        return;
    }

    // Si l'utilisateur est déjà connecté et essaie d'accéder à "/login" ou "/demandemdp", redirige vers "/"
    if (store.getters.isLoggedIn && (to.path === '/login' || to.path === '/demandemdp')) {
        next('/');
        return;
    }

  // Vérifie si la route demandée existe
  const routeExists = router.getRoutes().some(route => route.path === to.path)
  if (!routeExists) {
      console.log("la route n'existe pas")
    next('/')
    return
  }
  next()
})

export default router
