import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faVuejs } from '@fortawesome/free-brands-svg-icons';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { BootstrapVue3 } from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './style/_custom.css';

import axios from 'axios';

// Configuration d'axios pour inclure le token dans les en-têtes si présent
const token = sessionStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

library.add(faVuejs, faSpinner, fas, far);
dom.watch();

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(BootstrapVue3);
app.use(router);
app.use(store);
app.mount('#app');